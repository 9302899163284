import classes from './Home.module.css'

function AboutMe() {
    return (
<>



<div>
    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>

    <div class="col-md-12">
        <div class="col-md-8 col-sm-12 col-xs-12 pull-left" id="page-content">
            <h3>Jonathan Zylberberg, Classical Guitarist</h3>
            <p>
                I discovered my passion for classical guitar at the age of eleven. <br />
                After a year of private lessons I started the Music Conservatory of Classical Music, which I finished at the age of twenty.
            </p>
            <p>
                With an extensive repertoire that spans across the centuries, my goal is to bring forth a rich and expressive sound with each stroke of the strings,
                expressing my love for this exquisite instrument in every note I play.
            </p>
            <p>I've had the pleasure to play in reputable venues such as Brock House, Four Seasons Hotel, Terminal City Club, UBC Botanical Garden, Shaughnessy Restaurant,
               Harrison Hot Springs Resort, Hart House amongst others</p>
            <p>I deeply enjoy playing in weddings, where I passionately deliver soulful melodies, creating a truly memorable musical experience for the newlyweds and their guests.</p>
            <p>I regularly perform in weddings and private events in the Vancouver area, British Columbia.</p>
        </div>

        <div class="col-md-4 pull-right hidden-sm hidden-xs center-block">
            <br />
            <img src="/img/Jonathan-mzt-min.jpg" class="img-responsive" style={{'box-shadow': '0 0 2px 4px white inset', 'border-radius': '2px'}} alt="Wedding Musician in Vancouver BC" />
        </div>
    </div>
</div>
<div style={{'height': '20px'}} class="col-md-12">&nbsp;</div>
<div>
    <div class="col-md-12 hidden-sm hidden-xs">
        <img src="/img/GoldSeparator.png" class="img-responsive center-block" alt="Wedding Musician in Vancouver BC" />
    </div>
</div>

</>
    )
}

export default AboutMe;