import classes from './Home.module.css'

function Contact() {
    return (
        <>

<div>

    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>

    <div class="col-md-12">
    <div className={`col-md-8 col-sm-12 col-xs-12 pull-left ${classes.pageContent}`}>
        <h3>Contact By Email</h3>

        <div class="input-group">
            <div class="input-group-btn">
                <button class="btn btn-block btn-default" type="button" onClick={() => window.location.href='mailto:jonathanguitar78@gmail.com'}>
                    <i class="glyphicon glyphicon-pencil"></i>
                </button>
            </div>
            <span style={{'paddingLeft': '15px'}}><strong><a href="mailto:jonathanguitar78@gmail.com" id="contactByEmail">jonathanguitar78@gmail.com</a></strong></span>
        </div>

        <hr />
    </div>

    <div class="col-md-4 pull-right hidden-sm hidden-xs center-block">
        <br />
        <img src="/img/Jonathan-mzt-min.jpg" class="img-responsive" style={{'boxShadow':'0 0 2px 4px white inset'}} alt="Wedding Musician in Vancouver BC" />
    </div>
    </div>
</div>
<div style={{'height': '20px'}} class="col-md-12">&nbsp;</div>        
        
        </>
    )
}

export default Contact;