import React, { useEffect, useState } from 'react';
import { useOutletContext } from "react-router-dom";
import classes from './Repertoire.module.css'
import ErrorModal from '../components/UI/ErrorModal'

function Repertoire (props) {

    const [selectedVideo, setSelectedVideo] = useState('');
    const { updateLength, outerBorder } = useOutletContext();

    const videoChangeHandler = (id) => {
        setSelectedVideo(id);
    };

    const errorHandler = () => {
        setSelectedVideo(null);
    };

    useEffect(() => {
        if(outerBorder.current) {
            updateLength(4000, outerBorder);
        }
    }, [outerBorder]);

    return(
<>
<div>
    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>

    <div class="col-md-12">
        <div className={`col-md-8 col-sm-12 col-xs-12 pull-left ${classes.pageContent}`}>
            <p><br />
                You can find the full versions of these songs and others on my&nbsp;
                <a href="//www.youtube.com/user/JonathanGuitar78/videos" target="_blank">YouTube Channel</a>
            </p>

            {selectedVideo && (
                <ErrorModal
                videoId={selectedVideo}
                onConfirm={errorHandler}
                />
            )}

            <div class="row col-md-12 col-sm-12 col-xs-12">

                <table class="table table-hover table-responsive" id="myTable">
                    <thead>
                        <tr>
                            <th>Song Name</th>
                            <th>Sound Track</th>
                        </tr>
                    </thead> 

                    <tbody>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Canon in D&nbsp;<small><i>Johann Pachelbel&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/GEYlTNG18zM')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song1" controls="controls">
                                    <source src="s/canond.ogg" type="audio/ogg" />
                                    <source src="s/canond.mp3" type="audio/mpeg" />
                                    <a href="s/canond.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Imagine&nbsp;<small><i>John Lennon&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/CfdtZeQwidw')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song30" controls="controls">
                                    <source src="s/imagine.ogg" type="audio/ogg" />
                                    <source src="s/imagine.mp3" type="audio/mpeg" />
                                    <a href="s/imagine.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Cavatina&nbsp;<small><i>Stanley Myers&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/xp5MfzGUI6w')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song2" controls="controls">
                                    <source src="s/cavatina.ogg" type="audio/ogg" />
                                    <source src="s/cavatina.mp3" type="audio/mpeg" />
                                    <a href="s/cavatina.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Spanish Romance&nbsp;<small><i>Narciso Yepes&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/nTS9khvC_3I')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song3" controls="controls">
                                    <source src="s/SR.ogg" type="audio/ogg" />
                                    <source src="s/SR.mp3" type="audio/mpeg" />
                                    <a href="s/SR.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Wedding March&nbsp;<small><i>Felix Mendelssohn&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/YK2Tq3xMHBo')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song4" controls="controls">
                                    <source src="s/weddingmarch.ogg" type="audio/ogg" />
                                    <source src="s/weddingmarch.mp3" type="audio/mpeg" />
                                    <a href="s/weddingmarch.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Chariots Of Fire&nbsp;<small><i>Vangelis&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/i8DLp_frYXs')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50px'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song25" controls="controls">
                                    <source src="s/chariotsoffire.ogg" type="audio/ogg" />
                                    <source src="s/chariotsoffire.mp3" type="audio/mpeg" />
                                    <a href="s/chariotsoffire.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Strangers In The Night&nbsp;<small><i>Frank Sinatra&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/bz0zfVBvdhs')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song26" controls="controls">
                                    <source src="s/strangersinthenight.ogg" type="audio/ogg" />
                                    <source src="s/strangersinthenight.mp3" type="audio/mpeg" />
                                    <a href="s/strangersinthenight.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Nessun Dorma&nbsp;<small><i>Giacomo Puccini&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/o8kFHEeG1r4')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song5" controls="controls">
                                    <source src="s/nessundorma.ogg" type="audio/ogg" />
                                    <source src="s/nessundorma.mp3" type="audio/mpeg" />
                                    <a href="s/nessundorma.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Memory, from Cats&nbsp;<small><i>Andrew Lloyd Webber&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/hAT9UzcPs7c')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song44" controls="controls">
                                    <source src="s/memory.ogg" type="audio/ogg" />
                                    <source src="s/memory.mp3" type="audio/mpeg" />
                                    <a href="s/memory.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Father and son&nbsp;<small><i>Cat Stevens&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/4VcMVSUAfSw')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song45" controls="controls">
                                    <source src="s/fatherandson.ogg" type="audio/ogg" />
                                    <source src="s/fatherandson.mp3" type="audio/mpeg" />
                                    <a href="s/fatherandson.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Ave Maria&nbsp;<small><i>Franz Schubert&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/1W1PtbPu2ug')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song48" controls="controls">
                                    <source src="s/avemaria.ogg" type="audio/ogg" />
                                    <source src="s/avemaria.mp3" type="audio/mpeg" />
                                    <a href="s/avemaria.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Con Te Partiro (Time to say goodbye)&nbsp;<small><i>Andrea Bocelli&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/9wUJzCowl6M')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song49" controls="controls">
                                    <source src="s/contepartiro.ogg" type="audio/ogg" />
                                    <source src="s/contepartiro.mp3" type="audio/mpeg" />
                                    <a href="s/contepartiro.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>My heart will go on (Titanic)&nbsp;<small><i>Celine Dion&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/oHkDwsRtSmY')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song50" controls="controls">
                                    <source src="s/titanic.ogg" type="audio/ogg" />
                                    <source src="s/titanic.mp3" type="audio/mpeg" />
                                    <a href="s/titanic.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Through the eyes of love (Ice Castles)&nbsp;<small><i>Melissa Manchester&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/xTcoMZNm-ew')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song51" controls="controls">
                                    <source src="s/lookingeyesoflove.ogg" type="audio/ogg" />
                                    <source src="s/lookingeyesoflove.mp3" type="audio/mpeg" />
                                    <a href="s/lookingeyesoflove.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Libiamo ne&#39; lieti calici - (La Traviata)&nbsp;<small><i>Verdi&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/bjD98rj9iGE')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song52" controls="controls">
                                    <source src="s/libiamo.ogg" type="audio/ogg" />
                                    <source src="s/libiamo.mp3" type="audio/mpeg" />
                                    <a href="s/libiamo.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Larghetto in D&nbsp;<small><i>Matteo Carcassi&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/mPG2LVS1kSw')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song6" controls="controls">
                                    <source src="s/larghettod.ogg" type="audio/ogg" />
                                    <source src="s/larghettod.mp3" type="audio/mpeg" />
                                    <a href="s/larghettod.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Air on G string&nbsp;<small><i>Johann. S. Bach&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/tLgaBlTFmJc')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song7" controls="controls">
                                    <source src="s/AirG.ogg" type="audio/ogg" />
                                    <source src="s/AirG.MP3" type="audio/mpeg" />
                                    <a href="s/AirG.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Love of my life&nbsp;<small><i>Queen&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/ry4iZDsGUuo')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song46" controls="controls">
                                    <source src="s/loveofmylife.ogg" type="audio/ogg" />
                                    <source src="s/loveofmylife.mp3" type="audio/mpeg" />
                                    <a href="s/loveofmylife.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Wonderful Tonight&nbsp;<small><i>Eric Clapton&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/Lugi6uQGDis')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song47" controls="controls">
                                    <source src="s/wonderfultonight.ogg" type="audio/ogg" />
                                    <source src="s/wonderfultonight.mp3" type="audio/mpeg" />
                                    <a href="s/wonderfultonight.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>My Way&nbsp;<small><i>Frank Sinatra&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/duRBhFmtmv0')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song8" controls="controls">
                                    <source src="s/myway.ogg" type="audio/ogg" />
                                    <source src="s/myway.mp3" type="audio/mpeg" />
                                    <a href="s/myway.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>La Vie En Rose&nbsp;<small><i>Edith Piaf&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/ogGsaAUPdEg')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song9" controls="controls">
                                    <source src="s/vierose.ogg" type="audio/ogg" />
                                    <source src="s/vierose.mp3" type="audio/mpeg" />
                                    <a href="s/vierose.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Somewhere Over The Rainbow&nbsp;<small><i>Judy Garland&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/6LO-3ZM0qRc')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song35" controls="controls">
                                    <source src="s/somewhererainbow.ogg" type="audio/ogg" />
                                    <source src="s/somewhererainbow.mp3" type="audio/mpeg" />
                                    <a href="s/somewhererainbow.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Raindrops keep falling on my head&nbsp;<small><i>David, Bacharach&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/kHl-o4lB2YQ')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song36" controls="controls">
                                    <source src="s/raindrops.ogg" type="audio/ogg" />
                                    <source src="s/raindrops.mp3" type="audio/mpeg" />
                                    <a href="s/raindrops.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Chiquitita&nbsp;<small><i>ABBA&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/yZZMIahaCpc')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song37" controls="controls">
                                    <source src="s/chiquitita.ogg" type="audio/ogg" />
                                    <source src="s/chiquitita.mp3" type="audio/mpeg" />
                                    <a href="s/chiquitita.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Killing me softly with his song&nbsp;<small><i>Roberta Flack&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/x_UWgqMatVg')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song38" controls="controls">
                                    <source src="s/killingmesoftly.ogg" type="audio/ogg" />
                                    <source src="s/killingmesoftly.mp3" type="audio/mpeg" />
                                    <a href="s/killingmesoftly.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Wonderful World&nbsp;<small><i>Louis Armstrong&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/Bt08kuyg7A0')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song39" controls="controls">
                                    <source src="s/wonderfulworld.ogg" type="audio/ogg" />
                                    <source src="s/wonderfulworld.mp3" type="audio/mpeg" />
                                    <a href="s/wonderfulworld.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Moon River&nbsp;<small><i>Henry Mancini, Johnny Mercer&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/w-cvlZvsfjw')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song40" controls="controls">
                                    <source src="s/moonriver.ogg" type="audio/ogg" />
                                    <source src="s/moonriver.mp3" type="audio/mpeg" />
                                    <a href="s/moonriver.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Yellow Submarine&nbsp;<small><i>The Beatles&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song41" controls="controls">
                                    <source src="s/yellowsubmarine.ogg" type="audio/ogg" />
                                    <source src="s/yellowsubmarine.mp3" type="audio/mpeg" />
                                    <a href="s/yellowsubmarine.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Here Comes The Sun&nbsp;<small><i>The Beatles&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song42" controls="controls">
                                    <source src="s/herecomesthesun.ogg" type="audio/ogg" />
                                    <source src="s/herecomesthesun.mp3" type="audio/mpeg" />
                                    <a href="s/herecomesthesun.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Yesterday&nbsp;<small><i>The Beatles&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song10" controls="controls">
                                    <source src="s/yesterday.ogg" type="audio/ogg" />
                                    <source src="s/yesterday.mp3" type="audio/mpeg" />
                                    <a href="s/yesterday.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Sounds of Silence&nbsp;<small><i>Simon and Garfunkel&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/Atz2DhHwYo0')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song27" controls="controls">
                                    <source src="s/soundsofsilence.ogg" type="audio/ogg" />
                                    <source src="s/soundsofsilence.mp3" type="audio/mpeg" />
                                    <a href="s/soundsofsilence.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>All You Need Is Love&nbsp;<small><i>The Beatles&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/R_YB0J6w5_Y')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song28" controls="controls">
                                    <source src="s/allyouneedislove.ogg" type="audio/ogg" />
                                    <source src="s/allyouneedislove.mp3" type="audio/mpeg" />
                                    <a href="s/allyouneedislove.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Across The Universe&nbsp;<small><i>The Beatles&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/08GS0yjezeg')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song29" controls="controls">
                                    <source src="s/acrosstheuniverse.ogg" type="audio/ogg" />
                                    <source src="s/acrosstheuniverse.mp3" type="audio/mpeg" />
                                    <a href="s/acrosstheuniverse.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Di Provenza il Mar&nbsp;<small><i>La Traviata, Verdi&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/v8RBH32ijv0')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song43" controls="controls">
                                    <source src="s/diprovenza.ogg" type="audio/ogg" />
                                    <source src="s/diprovenza.mp3" type="audio/mpeg" />
                                    <a href="s/diprovenza.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>In My Life&nbsp;<small><i>The Beatles&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/bvH5XFNfcZ8')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song31" controls="controls">
                                    <source src="s/inmylife.ogg" type="audio/ogg" />
                                    <source src="s/inmylife.mp3" type="audio/mpeg" />
                                    <a href="s/inmylife.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>A Thousand Years&nbsp;<small><i>Christina Perri&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/j1ypUhoNnnQ')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song32" controls="controls">
                                    <source src="s/thousandyears.ogg" type="audio/ogg" />
                                    <source src="s/thousandyears.mp3" type="audio/mpeg" />
                                    <a href="s/thousandyears.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Oh Sole Mio&nbsp;<small><i>Di Capua&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/aqFpOSYDUp0')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song33" controls="controls">
                                    <source src="s/osolemio.ogg" type="audio/ogg" />
                                    <source src="s/osolemio.mp3" type="audio/mpeg" />
                                    <a href="s/osolemio.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Feels Like Home&nbsp;<small><i>Chantal Kreviazuk&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song34" controls="controls">
                                    <source src="s/feelslikehome.ogg" type="audio/ogg" />
                                    <source src="s/feelslikehome.mp3" type="audio/mpeg" />
                                    <a href="s/feelslikehome.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Julia Florida&nbsp;<small><i>Agustin Barrios&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/QspcYL52X2g')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song11" controls="controls">
                                    <source src="s/jf.ogg" type="audio/ogg" />
                                    <source src="s/jf.mp3" type="audio/mpeg" />
                                    <a href="s/jf.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Prelude BWV 998&nbsp;<small><i>Johann S. Bach&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/4uPK8yV1hzE')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song12" controls="controls">
                                    <source src="s/prelude998.ogg" type="audio/ogg" />
                                    <source src="s/prelude998.mp3" type="audio/mpeg" />
                                    <a href="s/prelude998.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Falling Slowly&nbsp;<small><i>Glen Hansard&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song13" controls="controls">
                                    <source src="s/fallingslowly.ogg" type="audio/ogg" />
                                    <source src="s/fallingslowly.mp3" type="audio/mpeg" />
                                    <a href="s/fallingslowly.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Fix You&nbsp;<small><i>Coldplay&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song14" controls="controls">
                                    <source src="s/fixyou.ogg" type="audio/ogg" />
                                    <source src="s/fixyou.mp3" type="audio/mpeg" />
                                    <a href="s/fixyou.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Prelude, from Cello Suite Number I&nbsp;<small><i>J.S.Bach&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/T1hxhfBJjHA')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song15" controls="controls">
                                    <source src="s/prelude1.ogg" type="audio/ogg" />
                                    <source src="s/prelude1.MP3" type="audio/mpeg" />
                                    <a href="s/prelude1.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Lagrima&nbsp;<small><i>Francisco Tarrega&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/vjp8tYcg1Bw')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song16" controls="controls">
                                    <source src="s/lagrima.ogg" type="audio/ogg" />
                                    <source src="s/lagrima.MP3" type="audio/mpeg" />
                                    <a href="s/lagrima.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Recuerdos de la Alhambra&nbsp;<small><i>Francisco Tarrega&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/QBO9G3kX6nI')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song17" controls="controls">
                                    <source src="s/recalhambra.ogg" type="audio/ogg" />
                                    <source src="s/recalhambra.MP3" type="audio/mpeg" />
                                    <a href="s/recalhambra.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>El Noi De La Mare&nbsp;<small><i>Miguel Llobet&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/3WiGfb0BxTM')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song18" controls="controls">
                                    <source src="s/noimare.ogg" type="audio/ogg" />
                                    <source src="s/noimare.mp3" type="audio/mpeg" />
                                    <a href="s/noimare.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>El Dia Que Me Quieras&nbsp;<small><i>Gardel - Lepera&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/RL9XvJLgKz4')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song19" controls="controls">
                                    <source src="s/dqm.ogg" type="audio/ogg" />
                                    <source src="s/dqm.MP3" type="audio/mpeg" />
                                    <a href="s/dqm.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Granada&nbsp;<small><i>Isaac Albeniz&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/UATxNL2An7I')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song20" controls="controls">
                                    <source src="s/Granada.ogg" type="audio/ogg" />
                                    <source src="s/Granada.MP3" type="audio/mpeg" />
                                    <a href="s/Granada.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>El Ultimo Tremolo&nbsp;<small><i>Agustin Barrios&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/pZi74CzRcU4')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song21" controls="controls">
                                    <source src="s/ultimotremolo.ogg" type="audio/ogg" />
                                    <source src="s/ultimotremolo.MP3" type="audio/mpeg" />
                                    <a href="s/ultimotremolo.MP3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>La Cumparsita&nbsp;<small><i>Gerardo Matos Rodriguez&nbsp;
                                </i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song22" controls="controls">
                                    <source src="s/cumparsita.ogg" type="audio/ogg" />
                                    <source src="s/cumparsita.mp3" type="audio/mpeg" />
                                    <a href="s/cumparsita.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Waltz Number III&nbsp;<small><i>Agustin Barrios&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/yDHuC4w2YQs')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song23" controls="controls">
                                    <source src="s/vals3.ogg" type="audio/ogg" />
                                    <source src="s/vals3.mp3" type="audio/mpeg" />
                                    <a href="s/vals3.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                        <tr style={{'height': '20px'}}>
                            <td style={{'width': '50%'}}>Prelude Number V&nbsp;<small><i>Heitor Villa Lobos&nbsp;
                                <a href="#" onClick={() => videoChangeHandler('https://www.youtube.com/embed/MhLjiHfpKBs')}>Watch video</a></i></small>
                            </td>
                            <td style={{'width': '50%'}}>
                                <audio preload="none" class="col-md-10 col-xs-4 col-sm-4 pull-left" id="song24" controls="controls">
                                    <source src="s/prelude5.ogg" type="audio/ogg" />
                                    <source src="s/prelude5.mp3" type="audio/mpeg" />
                                    <a href="s/prelude5.mp3">horse</a>
                                    An html5-capable browser is required to play this audio.
                                </audio>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-4 pull-right hidden-sm hidden-xs center-block">
            <br />
            <img src="/img/bodabn-min.jpg" class="img-responsive img-rounded" style={{'box-shadow': '0 0 2px 4px white inset'}} alt="Wedding Musician in Vancouver BC" />
        </div>
    </div>
</div>
<div style={{'height': '20px'}} class="col-md-12">&nbsp;</div>


</>


    )




}

export default Repertoire;