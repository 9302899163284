import { Outlet } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import MainNavigation from "../components/MainNavigation";
import { useRef } from "react";
import { Link } from "react-router-dom";

function RootLayout() {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 891px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 890px)' })
    const outerBorder = useRef();

    const updateLength = (val, outer) => {
        outer.current.style.minHeight = `${val}px`;
    };
    
    return (
        <>
        <div className="container white-background container-no-padding">
            <div className="navbar navbar-default navbar-no-marginbottom" role="navigation" id="menuBar">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/Home"><img src="/img/logotransparent.gif" className="img-responsive" style={{'position':'absolute', 'left': '0px', 'top': '0px', 'width': '120px'}} alt="Guitarist for Wedding Ceremonies, Receptions and Events in Vancouver" /></Link>
                    </div>
                    <MainNavigation />                    
                </div>
            </div>
            
            {isDesktopOrLaptop && (
                <div className="opacity1" style={{'minHeight': '1300px' }} id="outerBorder" ref={outerBorder} >
                    <Outlet context={{updateLength, outerBorder}} />
                </div>
            )}
            {isTabletOrMobile && (
                <div className="opacity1" style={{'minHeight': '750px' }} id="outerBorder" ref={outerBorder} >
                    <Outlet context={{updateLength, outerBorder}}  />
                </div>
            )}            

        </div>
        </>
    )
}

export default RootLayout;