import { Link } from "react-router-dom";
import classes from './Home.module.css'

function Prices() {
    return (
        <>
        
        <div>
    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>
    
        <div class="col-md-12">
            <div className={`col-md-8 col-sm-12 col-xs-12 pull-left ${classes.pageContent}`}>
                <h3>Weddings</h3>
                <p>Basic Price: <b>$300</b></p>
                <p>Includes:</p>
                <ul>
                    <li>Attending the wedding rehearsal</li>
                    <li>30 Minutes of Pre-Ceremony music</li>
                    <li>Processional</li>
                    <li>Signing of the Registry</li>
                    <li>Recessional</li>
                    <li>30 minutes of post-recessional background music</li>
                </ul>

                <p><b>Notes:</b></p>

                <ul class="notes">
                    <li>
                        Please check my <Link to="/Repertoire">Repertoire</Link> to see my full list of songs.&nbsp;
                        Most of them can also be found on my <a href="http://www.youtube.com/user/JonathanGuitar78/videos" target="_blank">YouTube Channel</a>.&nbsp;
                        If you require a song that is not currently in my repertoire there will be an extra charge. Please allow two or three weeks for this.<br /><br />
                    </li>
                    <li>
                        If you require music for a longer time, this is billed at a <b>$75</b>/hour rate.<br /><br />
                    </li>
                    <li>
                        Please note that there is no travel cost if your venue is 35kms away or less from Vancouver.<br />
                        Service between 36kms and 60kms away from Vancouver has a $50 travel cost.<br />
                        Service between 61kms and 85kms away from Vancouver has a $70 travel cost. <br />
                        For service further than 85kms from Vancouver please ask for a quote.<br /><br />
                    </li>
                </ul>

                <h3>Events</h3>
                <p>Private events have a minimum fee of $300 plus <b>$75</b> for every hour after the first 90 minutes.</p>

            </div>

            <div class="col-md-4 pull-right hidden-sm hidden-xs center-block">
                <br />
                <img src="/img/Jonathan-mzt-min.jpg" class="img-responsive" style={{'box-shadow': '0 0 2px 4px white inset', 'border-radius': '2px'}} alt="Wedding Musician in Vancouver BC" />
            </div>
        </div>
    
</div>
        </>
    )
}

export default Prices;