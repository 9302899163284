import { Link } from "react-router-dom";

function MainNavigation() {
    return (
        <div className="collapse navbar-collapse navbar-ex1-collapse">
            <ul className="nav navbar-nav navbar-right">
                <li><Link to="/Home">Home</Link></li>
                <li><Link to="/AboutMe">About Me</Link></li>
                <li><Link to="/Repertoire">Repertoire</Link></li>
                <li><Link to="/Prices">Prices</Link></li>
                <li><Link to="/Testimonials">Testimonials</Link></li>
                <li><Link to="/Contact">Contact</Link></li>
            </ul>
        </div>
    )
}

export default MainNavigation;