import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import AboutMe from "./pages/AboutMe";
import RootLayout from "./pages/Root";
import Repertoire from "./pages/Repertoire";
import Prices from "./pages/Prices";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";

const router = createBrowserRouter([
  { path: '/', 
    element: <RootLayout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/Home', element: <Home /> },
      { path: '/AboutMe', element: <AboutMe /> },
      { path: '/Repertoire', element: <Repertoire /> },
      { path: '/Prices', element: <Prices /> },
      { path: '/Testimonials', element: <Testimonials /> },
      { path: '/Contact', element: <Contact /> },
      { path: '*', element: <Home /> },      
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
