import classes from './Home.module.css'

function Home() {

    function calculateYears() {
        let currentTime = new Date().getTime();
        let birthDateTime= new Date(2009, 1, 2);
        let difference = (currentTime - birthDateTime)
        var ageInYears=difference/(1000*60*60*24*365)
        return (               
            <>{ageInYears.toFixed()}&nbsp;year{ageInYears > 1 ? 's' : ''}</>
        )
    }

    return (
<>
<div>
    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <h3>Jonathan Zylberberg, Classical Guitarist</h3>
    </div>

    <div class="col-md-12" className={classes.pageContent}>

        <div class="col-md-2 col-lg-2 visible-md visible-lg">&nbsp;</div>

        <div class="col-md-4 visible-sm visible-xs center-block">
            <img src="/img/Jonathan3-xs-min.png" class="img-responsive" style={{'box-shadow': '0 0 2px 2px white inset', 'border-radius': '2px'}} alt="Wedding Musician in Vancouver BC" />
        </div>

        <div class="col-md-8 hidden-sm hidden-xs">
            <img src="/img/Jonathan3-min.png" class="img-responsive" style={{'box-shadow': '0 0 2px 2px white inset', 'border-radius': '2px'}} alt="Wedding Musician in Vancouver BC" />
        </div>

        <div class="col-md-2 col-lg-2 visible-sm visible-xs">&nbsp;</div>

        <div class="col-md-12 col-lg-12">&nbsp;</div>

        <div class="col-md-12 col-sm-12 col-xs-12 center-block" >

            <div class="col-md-2 col-lg-2 visible-md visible-lg">&nbsp;</div>

            <div class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                <p>Welcome!</p>
                <p>I am a classical guitarist graduated from Classical Music Conservatory in Buenos Aires, Argentina.<br></br>
                   I offer specialised musical services for wedding ceremonies, receptions, corporate events, award ceremonies, holiday parties and other special occasions.</p>
                <p>I have been playing in weddings and private events in the Vancouver area for the past {calculateYears()},
                    with the goal of transporting listeners to the realms of beauty and emotion in your memorable events.</p>
                <p>My commitment to providing high quality performances is reliant on my working closely with clients, ensuring that I meet and exceed their expectations.</p>
                <p>My repertoire extends from the classical ballads, classical music to Spanish and Latin American folk music, which will bring charm and a distinctive vibe to your wedding.</p>
                <p>Please feel free to contact me at <b><a href="mailto:jonathanguitar78@gmail.com">jonathanguitar78@gmail.com</a></b> for a detailed quote.</p>
            </div>

            <div class="col-md-2 col-lg-2 visible-md visible-lg">&nbsp;</div>

        </div>

    </div>

</div>
<div style={{'height': '20px'}} class="col-md-12">&nbsp;</div>
<div>
    <div class="col-md-12 hidden-sm hidden-xs">
        <img src="/img/GoldSeparator.png" class="img-responsive center-block" alt="Wedding Musician in Vancouver BC" />
    </div>
</div>
<div style={{'height': '170px'}} class="col-md-12">&nbsp;</div>
</>
    );

}

export default Home;

