import classes from './Home.module.css'
import React, { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

function Testimonials() {

    const { updateLength, outerBorder } = useOutletContext();
    useEffect(() => {
        if(outerBorder.current) {
            updateLength(4000, outerBorder);
        }
    }, [outerBorder]);

    return (
        <>
<div>
    <div class="col-md-12" style={{'height': '30px'}}></div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <span className={classes.topsign}>Classical Guitarist for Weddings in Vancouver, British Columbia</span>
        </div>
    </div>

    <div class="col-md-12">
        <div className={`col-md-8 col-sm-12 col-xs-12 pull-left ${classes.pageContent}`}>
            <div class="row col-md-12 col-sm-12 col-xs-12">
                <table class="table table-hover table-responsive" id="myTable">
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>
                            <p>&quot;Just a little message to thank you Jonathan for your performance at our company event this past Thursday, May 12th 2016. 
                                You provided a wonderful atmosphere and our guests loved your music! It was never over powering or loud which enable for great mingling to take place. 
                                The music was a perfect balance of entertainment and background music. You were easy to deal with, very professional and I’d recommend you to anyone looking to add a little spice to their upcoming function.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Aleta Klassen, Colonial Countetops, Burnaby, May 12th, 2016</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;Jonathan was the guitarist for our wedding ceremony on July 25, 2015 at Aberthau Mansion in Pt. Grey and he did such a wonderful job. 
                                He was so easy to work with in the months leading up to the wedding - always quick to respond with helpful answers to all 
                                our questions. He has a great, comprehensive repertoire but when I wanted a specific song played as I walked 
                                down the aisle that was not on his repertoire he was willing to learn it in time for our wedding and it meant so 
                                much to us! On the wedding day itself, Jonathan was extremely professional and made our ceremony that much more special. 
                                We received a lot of positive feedback from our guests as well, who really enjoyed his music before, during, and after the ceremony. 
                                I would definitely highly recommend Jonathan to anyone looking for an extremely professional, kind, easy to work with, and very talented musician.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Rony and Jonathan, married July of 2015 at the Aberthau Mansion, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;Jonathan was the guitarist for my wedding ceremony on August 23rd, 2014 at Brix Wine Bar in Yaletown and he was amazing! 
                                Initially I wanted a violinist but I listened to his music on YouTube and was completely sold. Not only is he supremely talented, 
                                he is extremely professional, a pleasure to work with and has VERY reasonable rates in comparison to other musicians 
                                (trust me, I did the research!). I would recommend him in a heartbeat! His music was absolutely beautiful 
                                (especially his Spanish guitar) and it added something truly special to our wedding day. Thank you Jonathan!!!&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Crystal and Alex, married August of 2014 at the Brix Restaurant, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;Thank you Jonathan for making our special day extra special. We are both glad to have found you. Music holds a very special 
                                place in our hearts and you helped make that happen today. Thank you!!&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Irvin and Kevin, married August of 2014 at the Tea House Restaurant, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;We had Jonathan play music during our ceremony on July 26th, 2014. Jonathan arrived early to get setup and was very professional. 
                                He coordinated with the venue and the officiant to make the ceremony run very beautiful and smoothly. He played very romantic 
                                and beautiful music through the ceremony. Even though it was a really hot day, Jonathan stayed afterwards and continued to 
                                play music. 
                                Jonathan also attended our wedding rehearsal and also played music during our rehearsal. 
                                It definitely made the whole wedding rehearsal very nice and also helped a lot in the ceremony planning. 
                                I would highly recommend Jonathan to anyone looking for a professional and talented musician for a very reasonable price&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Kaitlyn and Justin, married July of 2014 at the Vancouver Golf Club, Coquitlam</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;We were so lucky to find Jonathan just 10 days before our wedding in July. 
                                I got to know him through his website which I have to say was very persuading. 
                                I  was attracted by his music first and then his reasonable price rate made me contacting him immediately...We were so thankful to have Jonathan playing for our wedding ceremony, 
                                him and his beautiful music added a classical and joyful ambience for our wedding. 
                                I highly recommend Jonathan to anyone looking for a good musician for their wedding or for a special event.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Daphne and Will, married July of 2013 at the Hart House, Burnaby</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;Jonathan helped create a wonderful and relaxed mood for our outdoor wedding at Brock House. 
                                He willingly shared his experience, and helped make our day a success. We would recommend him to anyone, 
                                friend or stranger, who&#39;s seeking a musician for their wedding ceremony.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Kevin and Tammy, married May of 2013 at the Brock House, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;Jonathan helped to make our wedding extra special. 
                                The songs he played for our entrance held special meaning for us and he played them wonderfully.  He played when guests were taking their seats, when the ceremony began, during the certificate signing, and for the exit. His guitar filled the air so beautifully and just added to the wonderfulness of the event. 
                                Best of all, Jonathan was experienced with performing at weddings and just knew how to work with the coordinators, venue director, commissioner, and the wedding party to ensure everything went smoothly.  We definitely recommend Jonathan for your wedding.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Yvonne and Brian, married September of 2012 at the Shaughnessy Restaurant, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;For our wedding in August of 2011, I was looking for a classical guitarist to play during our ceremony.  
                                Through a friend, I was referred to Jonathan.  His music was beautiful and added a really special touch to our ceremony.  
                                In addition, his rates were very reasonable and his communication was great - two things that a bride really appreciates!  
                                He even came to our rehearsal to get a feel for the venue and how the ceremony would proceed as it was in a Catholic church.  
                                I would not hesitate in recommending Jonathan to any bride or anyone looking to add ambiance to a special event.&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Lindsey and Tony, married August of 2011 at the Immaculate Heart of Mary Croatian Parish, Vancouver</i></small></p>
                        </td>
                        </tr>
                        <tr>
                        <td>
                            <p>&quot;We met Jonathan when he was playing Spanish Guitar at Cafe Barcelona, in Vancouver. We were very impressed, 
                                and as Miguel is from Spain, we hired him to play in Mexico at our wedding. It was one of the highlights of 
                                our beautiful wedding. Everyone complimented him! Muchas Gracias Jonathan!&quot;
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td align="right">
                            <p><small><i>Miguel and Viri, married October of 2011 at the Gran Mayan Hotel, Mazatlan, Mexico</i></small></p>
                        </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-4 pull-right hidden-sm hidden-xs center-block">
            <br />
            <img src="/img/Jonathan2-min.jpg" class="img-responsive img-rounded" style={{'box-shadow': '0 0 2px 4px white inset'}} alt="Wedding Musician in Vancouver BC" />
        </div>
    </div>
</div>
<div style={{'height': '20px'}} class="col-md-12">&nbsp;</div>        
        
        
        </>
    )
}

export default Testimonials;